<template>
  <div class="menu">
    <div class="container">
      <div class="row">
        <div class="menu__list col-md-10 col-sm-12" v-if="menu">
          <div class="menu__item" v-for="(item, index) in menu" :key="index">
            <router-link :to="item.link" class="menu__item-link">
              {{item.title}}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props:['menu', 'toggleVacationMode']
}
</script>
<style lang="scss" scoped>
@import "src/assets/styles/styles";
.menu{
  min-height: 50px;
  width: 100%;
  background-color: $white-grey;
  &__list{
    display: flex;
    min-height: 50px;
    align-items: center;
  }
  &__item{
    font-family: 'Inter', Arial;
    font-weight: 400;
    font-size: 14px;
    margin-left: 50px;
    &:first-child{
      margin-left: 0;
    }
    a{
      color: $gray;
      &.router-link-active{
        color: $blue;
      }
    }
  }
  .checkbox-block{
    height: 50px;
    margin: 0;
    justify-content: flex-end;
    .checkbox{
      background: $white;
      margin-right: 20px;
    }
  }
}
</style>
